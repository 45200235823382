<template>
<main class="about">
    <Breadcrumb></Breadcrumb>

    <section class="">
      <PageHeader
        title="A Powerhouse in Academics"
        backgroundImage="sung-hee-kim-teaching-in-laboratory.jpg"
        backgroundColor="transparent"
        textColor="white"
        overlay="dark"
        align="center"
        size="lg"
      >
        <p>
          Georgia Bulldogs are succeeding at the highest levels, helping us
          become recognized as one of the nation’s top public universities in
          prominent rankings. Our students thrive in a culture of academic
          excellence that values hands-on learning experiences and innovative
          educational programs, all led by world-class faculty.
        </p>
      </PageHeader>
    </section>

    <section class="py-5">
      <Section
        class=""
        type="inset"
        imgSide="right"
        imgURL="academic-excellence/blc.jpg"
      >
        <h3>Top 20 for 9 Straight Years</h3>
        <p>
          UGA has been ranked as a Top 20 U.S. Public University for the ninth 
          consecutive year by <em>U.S. News &amp; World Report</em>. Our
          standing as one of the best public institutions in the nation is a
          testament to our exceptional learning initiatives, academic programs,
          and world-class faculty.
        </p>

        <Btn
          href="https://news.uga.edu/usnwr-rankings-top-20-2024/"
          color="primary"
          size="md"
          >Learn more</Btn
        >
      </Section>
    </section>

    <!-- <section class="">
      <PageHeader
        title="A Powerhouse in Academics"
        backgroundImage="sung-hee-kim-teaching-in-laboratory.jpg"
        backgroundColor="transparent"
        textColor="white"
        overlay="dark"
        align="center"
        size="lg"
      >
        <p>UGA has been ranked as a Top 20 U.S. Public University for the seventh consecutive year by <em>U.S. News & World Report</em>. Our standing as the 16th best public institution in the nation is a testament to our exceptional learning initiatives, academic programs, and world-class faculty.</p>
        <Btn
          href="https://news.uga.edu/usnwr-2023-ranking-no-16/"
          color="primary"
          style="max-width: 500px; margin: 0 auto;"
          size="md"
        >Learn more</Btn>
      </PageHeader>
    </section> -->

    <section class="mb-4">
      <div class="row g-0">
        <div class="col-md-4">
          <Figure
            prefix="Top "
            :endAmount="20"
            :startAmount="50"
            subheading="Best Public Colleges, <em>U.S. News &amp; World Report</em>"
            backgroundColor="grey"
            textColor="white"
            :textured="false"
          ></Figure>
        </div>
        <div class="col-md-4">
          <Figure
            prefix="#"
            :endAmount="11"
            :startAmount="40"
            subheading="Top Public Universities in the U.S., <em>Niche</em>"
            backgroundColor="black"
            textColor="white"
            :textured="false"
          ></Figure>
        </div>
        <div class="col-md-4">
          <Figure
            prefix="#"
            :endAmount="16"
            :startAmount="50"
            subheading="Best Public Institution for Internships, <em>Princeton Review</em>"
            backgroundColor="red"
            textColor="white"
            :textured="false"
          ></Figure>
        </div>
      </div>
    </section>

    <section class="py-5">
      <div class="row container-lg mx-auto">
        <div class="col-md-6" data-aos="fade-up">
          <QuickFact imageURL="value/32776-085.jpg" heading="Hands-on Learning">
            <p>
              In 2016, UGA became the nation’s largest university with an
              experiential learning requirement for all undergraduates – giving
              our students a competitive edge after graduation and further
              bolstering UGA’s national reputation as an innovative leader in
              undergraduate education.
            </p>
          </QuickFact>
        </div>
        <div class="col-md-6" data-aos="fade-up">
          <QuickFact
            imageURL="research/38868-019.jpg"
            heading="Discovery and Innovation"
          >
            <p>
              UGA’s research breakthroughs— in the lab and in the field—make our
              food safer, contribute to a stronger economy, protect our oceans,
              preserve our forests, and prevent diseases in plants, animals, and
              people.
            </p>
            <p>
              Undergraduate students can pursue advanced research from their
              first year at UGA through the Center for Undergraduate Research
              Opportunities (CURO).
            </p>
          </QuickFact>
        </div>
      </div>
    </section>

    <section class="mb-4">
      <div class="row g-0">
        <div class="col-md-4">
          <Figure
            prefix=""
            :endAmount="28"
            :startAmount="5"
            subheading="Rhodes Scholars"
            backgroundColor="black"
            textColor="white"
            textured
          ></Figure>
        </div>
        <div class="col-md-4">
          <Figure
            :endAmount="96"
            :startAmount="0"
            suffix="%"
            subheading="of 2022 graduates were employed or attending graduate school within 6 months"
            backgroundColor="red"
            textColor="white"
            textured
          ></Figure>
        </div>
        <div class="col-md-4">
          <Figure
            prefix="#"
            :endAmount="2"
            :startAmount="100"
            suffix=""
            subheading="Military Friendly Tier 1 Research Institution, <em>Viqtory Media</em>"
            backgroundColor="grey"
            textColor="white"
            textured
          ></Figure>
        </div>
      </div>
    </section>

    <!-- <section class="py-5">
      <Section
        class=""
        type="inset"
        imgSide="left"
        imgURL="academic-excellence/35897-050.jpg"
      >
        <h3>Driving Discovery and Innovation</h3>
        <p>UGA’s research breakthroughs— in the lab and in the field—make our food safer, contribute to a stronger economy, protect our oceans, preserve our forests, and prevent diseases in plants, animals, and people.</p>

        <p>This drive to discover is advancing growth in R&D expenditures. And our research has led to the development of hundreds of commercial products. </p>
      </Section>
    </section> -->

    <section class="py-5">
      <Section
        class=""
        type="inset"
        imgSide="left"
        imgURL="academic-excellence/35897-050.jpg"
      >
        <h3>Top-Rated Honors College</h3>
        <p>
          The Jere W. Morehead Honors College enriches undergraduate experiences
          in and beyond the classroom through research, internships, and
          travel-study opportunities. Through smaller class sizes and a wide
          range of offerings, such as private faculty lectures, students can
          interact with faculty research and better prepare for professional
          advancement.
        </p>

        <Btn href="https://honors.uga.edu" color="primary" size="md"
          >Learn more about the Honors College</Btn
        >
      </Section>
    </section>
    <section>
      <Section
        type="standard"
        imgSide="left"
        imgURL="academic-excellence/class-of-2026-super-g.jpg"
        color="bg-dark bg-diagonal--dark"
        class="text-white"
      >
        <h3>Incoming Class Profile</h3>
        <p>
          The first-year students from the Class of 2027 were selected from the
          largest pool in the university’s history and are some of the most
          academically qualified in the University’s history.
        </p>
        <!-- <Btn
          color="light"
          href="https://www.admissions.uga.edu/admissions/admission-statistics/"
          class="mb-4"
        >Learn more about the Class of 2026</Btn> -->
        <Btn
          color="light"
          href="https://news.uga.edu/uga-welcomes-class-of-2028/"
          class="mb-4"
          >Learn more about the Class of 2028</Btn
        >
        <figure>
          <blockquote class="blockquote">
            <p>
              “This fall’s incoming class demonstrates a remarkable dedication
              to excellence. They will be in good company at UGA as they join an
              esteemed academic community that is committed to making a positive
              impact on our state, nation and world.”
            </p>
          </blockquote>
          <figcaption class="blockquote-footer text-white">
            President Jere W. Morehead
          </figcaption>
        </figure>
      </Section>
    </section>
    <!-- <section class="">
      <div class="row g-0">
        <div class="col-md-4">
          <Figure
            :endAmount="95"
            :startAmount="0"
            suffix="%"
            subheading="retention rate for incoming students who return for their sophomore year"
            backgroundColor="black"
            textColor="white"
            textured
          ></Figure>
        </div>
        <div class="col-md-4">
          <Figure
            prefix="Top "
            :endAmount="5"
            :startAmount="50"
            subheading="for new products to market for 9 consecutive years"
            backgroundColor="red"
            textColor="white"
            textured
          ></Figure>
        </div>
        <div class="col-md-4">
          <Figure
            :endAmount="65"
            :startAmount="10"
            suffix="%"
            subheading="in NIH/NSF expenditure over the past 6 years"
            backgroundColor="grey"
            textColor="white"
            textured
          ></Figure>
        </div>
      </div>
    </section> -->
  </main>
</template>

<script>
// @ is an alias to /src
import Btn from "@/components/Btn.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import PageHeader from "@/components/PageHeader.vue";
import Figure from "@/components/Figure.vue";
import QuickFact from "@/components/QuickFact.vue";
import Section from "@/components/Section.vue";

export default {
  name: "Academic-Excellence",
  metaInfo: {
    title: "Academic Excellence at UGA",
    description:
      "A leading powerhouse in academics, UGA is helping students succeed at the highest levels through hands-on learning and innovative educational programs.",
    og: {
      image: "",
      title: "",
      description: "",
    },
  },
  methods: {},
  data() {
    return {};
  },
  components: {
    Btn,
    PageHeader,
    Breadcrumb,
    Figure,
    QuickFact,
    Section,
  },
};
</script>

<style scoped lang="scss"></style>
